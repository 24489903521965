// UI
import CheckboxOutlets from './CheckboxOutlets/CheckboxOutlets';
import DataBar from 'containers/Modal/DistributionModals/DataBar/DataBar';
import ErrorsBlock from './ErrorsBlock/ErrorsBlock';

// Styles
import styles from './ReleaseDateBlock.module.css';

const ReleaseDateBlock = ({
	item,
	personal,
	setPersonal,
	outlets,
	selectOutletsDateCheckbox,
	changeSelectDateOutletsCheckbox,
	outletsList,
	errors,
	resetErrors,
	resetBackErrors,
}) => {
	return (
		<div className={styles.releaseDateBlock}>
			<DataBar
				item={item}
				modalType="distribution_date_edit"
				personal={personal}
				setPersonal={setPersonal}
				resetErrors={resetErrors}
				resetBackErrors={resetBackErrors}
			/>
			<ErrorsBlock errors={errors} item={item} />
			{outlets?.length > 1 && (
				<CheckboxOutlets
					item={item}
					selectOutletsDateCheckbox={selectOutletsDateCheckbox}
					changeSelectDateOutletsCheckbox={changeSelectDateOutletsCheckbox}
					outlets={outlets}
					personal={personal}
					setPersonal={setPersonal}
				/>
			)}
			{selectOutletsDateCheckbox && personal.distribution_outlets?.length > 0 && (
				<div className={styles.outletsDataBar}>
					<DataBar
						item={item}
						outlets={outletsList}
						modalType="distribution_outlets_edit"
						personal={personal}
						setPersonal={setPersonal}
						resetErrors={resetErrors}
						resetBackErrors={resetBackErrors}
					/>
				</div>
			)}
		</div>
	);
};

export default ReleaseDateBlock;
