// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Styles
import s from '../Distribution.module.css';

const ErrorsBlock = ({ errors }) => {
	const { isSlaPrivelegesForAccount } = useContext(AuthContext);

	return (
		<>
			{Object.keys(errors).includes('check_box_outlets') && (
				<div className={s.error_container}>
					<span className={s.error}>
						{
							<FormattedMessage
								id={`rod.error.${errors.check_box_outlets[0].rule}`}
							/>
						}
					</span>
				</div>
			)}
			{Object.keys(errors).includes(
				'distribution_outlets.0.delivery_start_time'
			) && (
				<div className={s.error_container}>
					<span className={s.error}>
						{<FormattedMessage id={'rod.error.delivery_later_sale'} />}
					</span>
				</div>
			)}
			{Object.keys(errors).includes('delivery_start_time') &&
				errors.delivery_start_time[0]?.rule ===
					"Sales start date must be no earlier than 1 days from today's date" && (
					<div className={s.error_container}>
						<span className={s.error}>
							{
								<FormattedMessage
									id={
										isSlaPrivelegesForAccount
											? 'rod.error.delivery_min_date_one'
											: 'rod.error.delivery_min_date_seven'
									}
								/>
							}
						</span>
					</div>
				)}
		</>
	);
};

export default ErrorsBlock;
