// Core
import React, { useEffect, useState } from 'react';

// Utils
import { getDateData } from 'material-design/ReleaseCreate/steps/Distribution/utils/getDateData.js';

// UI
import DistributionModal from '../DistributionModal';
import DateTimeZone from '../DateTimeZone/DateTimeZone';

const DistributionDateModal = ({
	item,
	onClose,
	personal,
	setPersonal,
	resetErrors,
	resetBackErrors,
}) => {
	const [data, setData] = useState({
		delivery_date: null,
		delivery_time: '',
		sale_date: null,
		sale_time: '',
		time_zone: null,
	});
	const [errors, setErrors] = useState({});

	const minDate =
		item.value === '7+'
			? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			: new Date(Date.now() + 24 * 60 * 60 * 1000);

	const setDeliveryTime = (value) => {
		setErrors({});
		setData({ ...data, delivery_time: value });
	};

	const setSaleTime = (value) => {
		setErrors({});
		setData({ ...data, sale_time: value });
	};

	const changeField = (field) => (e) => {
		setErrors({});

		switch (field) {
			case 'time_zone':
				setData({ ...data, time_zone: e.id });
				break;
			default:
				setData({ ...data, [field]: e });
				break;
		}
	};

	const submitHandler = () => {
		const {
			delivery_date,
			delivery_time,
			sale_date,
			sale_time,
			time_zone,
		} = data;

		const minDateStartDay = minDate.setHours(0, 0, 0, 0);
		const deliveryDateObj = new Date(delivery_date);
		const saleDateObj = new Date(sale_date);

		const deliveryDateObjWithTime = new Date(
			`${delivery_date}T${delivery_time}:00${time_zone}`
		);
		const saleDateObjWithTime = new Date(
			`${sale_date}T${sale_time}:00${time_zone}`
		);

		const deliveryStartTime = `${delivery_date}T${delivery_time}:00${time_zone}`;
		const saleStartDate = `${sale_date}T${sale_time}:00${time_zone}`;
		const currentErrors = {};

		if (deliveryDateObj.setHours(0, 0, 0, 0) < minDateStartDay) {
			currentErrors.delivery_date =
				item.value === '7+'
					? 'delivery_min_date_seven'
					: 'delivery_min_date_one';
		}

		if (saleDateObj.setHours(0, 0, 0, 0) < minDateStartDay) {
			currentErrors.sale_date =
				item.value === '7+' ? 'min_date_seven' : 'min_date_one';
		}

		if (deliveryDateObjWithTime >= saleDateObjWithTime) {
			currentErrors.sale_date = 'delivery_later_sale';
		}

		if (Object.keys(currentErrors).length > 0) {
			setErrors(currentErrors);
		} else {
			setErrors({});
			resetErrors();
			resetBackErrors();
			setPersonal({
				...personal,
				delivery_start_time: deliveryStartTime,
				sale_start_date: saleStartDate,
			});
			onClose();
		}
	};

	useEffect(() => {
		const updatedData = { ...data };

		const { date, time, gmt } = getDateData(
			personal.delivery_start_time
				? personal.delivery_start_time
				: personal.sale_start_date
		);
		updatedData.delivery_date = date;
		updatedData.delivery_time = time;
		updatedData.time_zone = gmt || null;

		if (personal.sale_start_date) {
			const { date, time, gmt } = getDateData(personal.sale_start_date);
			updatedData.sale_date = date;
			updatedData.sale_time = time;
			updatedData.time_zone = updatedData.time_zone || gmt || null;
		}

		setData(updatedData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DistributionModal
			title="rod.distribution.date.modal.title"
			subtitle={
				item && item.value === '7+'
					? 'rod.distribution.date.modal.subtitle.seven'
					: 'rod.distribution.date.modal.subtitle.one'
			}
			onClose={onClose}
			disableSubmitBtn={
				!data.delivery_date ||
				!data.delivery_time ||
				!data.sale_date ||
				!data.sale_time ||
				!data.time_zone ||
				Object.keys(errors).length > 0
			}
			submitHandler={submitHandler}
		>
			<DateTimeZone
				data={data}
				changeField={changeField}
				setDeliveryTimeInHHMM={setDeliveryTime}
				setSaleTimeInHHMM={setSaleTime}
				itemValue={item.value}
				errors={errors}
			/>
		</DistributionModal>
	);
};

export default DistributionDateModal;
